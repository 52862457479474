import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Bio" mdxType="SEO" />
    <p>{`Silvia Ariño Gurrea nació en 1995 en Zaragoza, España. Se graduó en Bellas Artes por la Universidad de Zaragoza y estudió un año en el extranjero en la Universidad de Loughborough (UK).`}</p>
    <p>{`Tiene un Máster en Producción Artística (Especialidad en Pensamiento Contemporáneo y Cultura Visual) por la Universitat Politècnica de València y otro Máster en Profesorado de Educación Secundaria (Universidad de Zaragoza). Actualmente es doctoranda del Programa de Doctorado en Arte: Producción e Investigación de la UPV, y colabora con el grupo de investigación `}<em parentName="p">{`Laboratorio de Creaciones Intermedia`}</em>{` (LCI).`}</p>
    <h2>{`2022`}</h2>
    <p>{`Becaria de gestión cultural MAEC-AECID (Programa CULT). Centro Cultural de España en México (Ciudad de México, México). 9 meses de duración (01/10/2022 al 30/06/2023).`}</p>
    <p>{`Seleccionada para recibir una de las 15 Ayudas a la Investigación en Ciencias Sociales y Humanidades de la Fundación Banco Sabadell 2022 (convocatoria para predoctorales). Ayuda de ámbito nacional de 1 año de duración (01/07/2022 al 30/06/2023).`}</p>
    <p>{`Profesora Asociada de la Universidad de Zaragoza. Tiempo parcial 6 horas. Área de Dibujo (19/04/2022 al 14/09/2022).`}</p>
    <p><em parentName="p">{`Jóvenes artistas, museos y viceversa`}</em>{`. Mesa redonda dentro del ciclo Distancia de Seguridad, organizado por ENEUM y LAAAB (Zaragoza, España).`}</p>
    <p><em parentName="p">{`Volver a lo nuevo`}</em>{`, comisariada por Colectivo Cuátrico. Fundación Amantes de Teruel (España).`}</p>
    <p><em parentName="p">{`¿Qué se cuece en Valencia?`}</em>{`, comisariada por Alessandra García. Contendor Cultural, Universidad de Málaga (España).`}</p>
    <h2>{`2021`}</h2>
    <p><em parentName="p">{`Prácticas artísticas para la construcción de Paz`}</em>{`. Seminario online. Artista invitada por Patricia Bernal Cortés y Blanca Matías Fernández. Universidad de Antioquia (Medellín, Colombia).`}</p>
    <p><em parentName="p">{`El Grito Sublime`}</em>{`. Comisariada por Martíllopis, Irene Covaleda y Elia Torrecilla. Casa de Cultura José Peris Aragó (Alboraya, España).`}</p>
    <p>{`Creación y coordinación junto a Ana Orcal de la `}<em parentName="p">{`I Jornada de arte, ecología y extrarradio`}</em>{` dentro de la II Muestra Internacional de Arte Contemporáneo realizado por Mujeres de PMAC (Teruel, España).`}</p>
    <p>{`Participación en la mesa redonda `}<em parentName="p">{`Placer y sexualidad femenina`}</em>{`, organizada por Holga Méndez y Laura Prados. FCSH de Teruel (España).`}</p>
    <p>{`Colaboración en la impartición y el desarrollo del curso `}<em parentName="p">{`Prenem la paraula`}</em>{`, de Neus Lozano-Sanfèlix, seleccionado dentro del programa `}<em parentName="p">{`Resistències artístiques. Processos artístics en entorns educatius. Castelló. (codi: 20AE96IN099)`}</em>{` (Castellón, España).`}</p>
    <h2>{`2020`}</h2>
    <p>{`Participante del grupo de trabajo `}<em parentName="p">{`PRESENTES DENSOS. En torno a las artes de vivir en un planeta herido`}</em>{`. Coordina Miguel Ángel Martínez. Institut Valencià d'Art Modern (IVAM) (Valencia, España).`}</p>
    <p><em parentName="p">{`El Grito`}</em>{`, comisariada por Martíllopis e Irene Covaleda. FCSH de Teruel (España). ISBN 978-84-09-25954-0`}</p>
    <p>{`Mención especial en `}<em parentName="p">{`Mail Me Art`}</em>{`. Circular Society Lab (Zaragoza, España). ISBN 978-84-09-22295-7`}</p>
    <p><em parentName="p">{`I Muestra Internacional de Arte Contemporáneo Realizado por Mujeres`}</em>{`, comisariada por la Plataforma de Mujeres en el Arte Contemporáneo (PMAC). IAACC Pablo Serrano (Zaragoza, España). ISBN 978-84-8380-416-2`}</p>
    <h2>{`2019`}</h2>
    <p>{`Comisariado junto a Ana Orcal de la exposición de artistas emergentes `}<em parentName="p">{`Ciudad desheredada`}</em>{`. FCSH de Teruel (España).`}</p>
    <p><em parentName="p">{`Cartografías del cuerpo, del género y del espacio urbano. Jornadas para la internacionalización del programa de doctorado en Arte: Producción e Investigación`}</em>{`. Departamento de Escultura, UPV (Valencia, España).`}</p>
    <p>{`Artista invitada en `}<em parentName="p">{`El Buit Es Reproduïx Entre`}</em>{`, de David Ábalos Aliaga. Octubre CCC (Valencia, España).`}</p>
    <p><em parentName="p">{`Cuerpo Urbano en Acción. Iª Edición Festival Internacional de Performances Mínimas Urbanas en Vídeo`}</em>{`. Comisariado por Elia Torrecilla y Miguel Molina-Alarcón (Varios). ISBN 978-84-09-19868-9`}</p>
    <p><em parentName="p">{`PAM!19`}</em>{`. Comisariada por el Máster Interuniversitario en Gestión Cultural (UPV-UV) y el Máster Universitario en Producción Artística (UPV) (Valencia, España).`}</p>
    <p><em parentName="p">{`Acción Spring(t) III. Congreso de Arte de Acción`}</em>{`. Acciones Complementarias del Vicedecanato de Cultura, UCM (Madrid, España).`}</p>
    <h2>{`2018`}</h2>
    <p><em parentName="p">{`EXFORMA`}</em>{`. Comisariada por la FBBAA de la Universidad de Sevilla. Espacio Laraña (Sevilla, España). ISBN 978-84-697-7935-4`}</p>
    <p><em parentName="p">{`MIL Festival`}</em>{`. Comisariada por Leocadio Tena Báguena (Teruel, España).`}</p>
    <p><em parentName="p">{`Cohete Toledo`}</em>{`. Comisariada por Laura Pinillos y Jorge de la Cruz. La Casa Franca (Toledo, España).`}</p>
    <p><em parentName="p">{`Acción Spring(t) II. Congreso de Arte de Acción`}</em>{`. Acciones Complementarias del Vicedecanato de Cultura, UCM (Madrid, España).`}</p>
    <p><em parentName="p">{`Garbo Festival`}</em>{`. Comisariada por el Grado en BBAA de la FCSH de Teruel. Palacio de Exposiciones y Congresos de Teruel (España). ISBN 978-84-09-02507-7`}</p>
    <h2>{`2017`}</h2>
    <p><em parentName="p">{`Todo podría ser de otra manera: estudio de casos`}</em>{`. Exposición individual final de grado. FCSH de Teruel (España).`}</p>
    <p><em parentName="p">{`Escenas. Exposición interdisciplinar`}</em>{`. Ana Orcal y Silvia Ariño Gurrea en el Taller de Pintura Bombardera (Teruel, España).`}</p>
    <p>{`Propuesta y gestión de la visita de la artista Núria Güell al congreso `}<em parentName="p">{`ON ART VII`}</em>{`. FCSH de Teruel (España).`}</p>
    <h2>{`2016`}</h2>
    <p><em parentName="p">{`MASS`}</em>{`. Comisariada por Loughborough University. Free Range Show (London, UK).`}</p>
    <h2>{`Publicaciones`}</h2>
    <p>{`Colaboración en `}<em parentName="p">{`Voces contra la trata`}</em>{`, un proyecto de Cruz Roja, Ayuntamiento de Teruel y FCSH de la Universidad de Zaragoza. 2022. Edición no venal - Publicación Gratuita. ISBN 978-84-09-43031-4. Diseño de cartelería.`}</p>
    <p>{`Prólogo al catálogo de la exposición `}<em parentName="p">{`El Grito Sublime`}</em>{` de LAPASSA Ediciones, pp. 12-16 (Zamit Digital 2022). ISBN 978-84-09-41189-4. Prólogo titulado `}<em parentName="p">{`La era de las mujeres`}</em>{`.`}</p>
    <p>{`Colaboración en `}<em parentName="p">{`Protoperformance en España (1834-1964)`}</em>{` del Laboratorio de Creaciones Intermedia (LCI) de la Universitat Politècnica de València. Ed. Weekend Proms. 2021. ISBN 978-84-09-03496-3. Capítulo titulado `}<em parentName="p">{`Errores`}</em>{`.`}</p>
    <p>{`Colaboración en `}<em parentName="p">{`Libro de Plástica para todas las Edades`}</em>{` del Laboratorio de Creaciones Intermedia (LCI) de la Universitat Politècnica de València. Ed. Sonda, Investigación en Artes y Letras. 2020. ISBN 978-84-09-10476-5. Capítulo titulado `}<em parentName="p">{`¿Por qué existen las sillas incómodas? II`}</em>{`.`}</p>
    <p>{`Colaboradora de prensa escrita en el Diario de Teruel. Sección individual `}<em parentName="p">{`Contrarrealidades`}</em>{` a página completa entre 12/11/2017 - 03/06/2018 y participación en la sección estival `}<em parentName="p">{`El Espejo de Tinta`}</em>{` (2019-2021).`}</p>
    <h2>{`Dirección de talleres`}</h2>
    <p>{`2019 `}<em parentName="p">{`Taller de lectura filosófica`}</em>{`. Facultad de BBAA de San Carles (Valencia, España).`}</p>
    <p>{`2016-2017 `}<em parentName="p">{`Taller de escritura creativa`}</em>{`. Espacio Luvitien (Teruel, España).`}</p>
    <p>{`2016 `}<em parentName="p">{`Taller de arteterapia para la tercera edad`}</em>{`. Abbeyfield (Loughborough, UK).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      